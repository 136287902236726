/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Project } from '../models/Project';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * Create a project
     * Creates a new project
     * @param clientId ID of the client the project belongs to
     * @param body Project object
     * @returns Project Success
     * @throws ApiError
     */
    public static createProject(
        clientId: string,
        body: Project,
    ): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clients/{clientId}/projects',
            path: {
                'clientId': clientId,
            },
            body: body,
            errors: {
                404: `Not found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a list of projects
     * Return a list of projects the logged in user has access to
     * @param clientId ID of the client the projects belongs to
     * @returns Project Success
     * @throws ApiError
     */
    public static listProjects(
        clientId: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/clients/{clientId}/projects',
            path: {
                'clientId': clientId,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

}
