/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientsService {

    /**
     * Create a client
     * Creates a new client
     * @param body Client object
     * @returns Client Success
     * @throws ApiError
     */
    public static createClient(
        body: Client,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clients',
            body: body,
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a list of clients
     * Returns a list of clients
     * @returns Client Success
     * @throws ApiError
     */
    public static listClients(): CancelablePromise<Array<Client>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/clients',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a client
     * Returns a client
     * @param clientId ID of the client to return
     * @returns Client Success
     * @throws ApiError
     */
    public static getClient(
        clientId: string,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/clients/{clientId}',
            path: {
                'clientId': clientId,
            },
            errors: {
                404: `Not found`,
                500: `Internal Server Error`,
            },
        });
    }

}
