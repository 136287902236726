import { TabView, TabPanel } from 'primereact/tabview';
import { useParams } from 'react-router-dom';
import TrackActivity from './trackActivity';

type ProjectProps = {
};

function Project(props: ProjectProps) {
    let params = useParams();
    return (
        <>
            {/* <TabMenu model={items} /> */}
            <TabView>
                <TabPanel header=" Track" leftIcon="pi pi-fw pi-clock">
                    <TrackActivity />
                </TabPanel>
                <TabPanel header="View" leftIcon="pi pi-fw pi-table">
                    Content II
                </TabPanel>
                <TabPanel header="Analytics" leftIcon="pi pi-fw pi-chart-bar">
                    Content III
                </TabPanel>
                <TabPanel header="Export" leftIcon="pi pi-fw pi-download">
                    Content III
                </TabPanel>
                <TabPanel header="Settings" leftIcon="pi pi-fw pi-cog">
                    Content III
                </TabPanel>
            </TabView>
            {params.projectId}
        </>
    );
}

export default Project;
