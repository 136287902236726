import { signInWithGoogle } from "./../firebase";

type LandingProps = {
};

function Landing(props: LandingProps) {
    return (
        <>
            <div className="login">
                    <button className="login__btn login__google" onClick={signInWithGoogle}>
                    Login with Google
                    </button>
            </div>
        </>
    );
}

export default Landing;
