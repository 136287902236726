import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import Menu from './home/menu';
import Project from './projects/project';
import Dashboard from './dashboard/dashboard';
import NewClient from './clients/newClient';
import NewProject from './projects/newProject';

import { User } from 'firebase/auth';
import { OpenAPI } from '../apiclient';


type HomeProps = {
    user: User
};

function Home(props: HomeProps) {
    OpenAPI.TOKEN = async () => {
        return await props.user.getIdToken()
    };

    return (<>
        <BrowserRouter>
            <header>
                <Menu user={props.user} />
            </header>
            <main className="bg-secondary">
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/clients/new" element={<NewClient />} />
                    <Route path="/clients/:clientId/projects/new" element={<NewProject />} />
                    <Route path="/clients/:clientId/projects/:projectId" element={<Project />} />
                    {/* <Route path="projects/:projectId" element={<Project />}>
                    <Route index element={<Track />} />
                    </Route> */}
                </Routes>
            </main>
        </BrowserRouter>
    </>);
}

export default Home;
