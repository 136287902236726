import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

import { Client } from '../../apiclient/models/Client';

export interface ClientState {
  clients: Array<Client>;
}

const initialState: ClientState = {
  clients: []
};

export const clientSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    addClient: (state, action: PayloadAction<Client>) => {
      state.clients.push(action.payload);
    },
    setClients: (state, action: PayloadAction<Array<Client>>) => {
        state.clients = action.payload
    }
  },
});

export const { addClient, setClients } = clientSlice.actions;

export const selectClients = (state: RootState) => state.menu.clients;

export default clientSlice.reducer;
