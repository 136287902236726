import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ProgressSpinner } from 'primereact/progressspinner';

import { auth } from "./firebase";
import Home from './components/home';
import Landing from "./components/landing";

import { OpenAPI, UsersService } from "./apiclient"

function App() {
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (user) {
      OpenAPI.TOKEN = async () => {
        return await user.getIdToken()
      };
      UsersService.signIn()
    }
  }, [user, loading]);

  if (loading) {
    return (
      <div className="flex align-items-center justify-content-center h-screen">
        <ProgressSpinner />
      </div>
    )
  }

  if (user) {
    return <Home user={user} />
  }

  if (error) {
    console.error(error)
  }

  return <Landing />
}

export default App;
