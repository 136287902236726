import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import {
    Client,
    ClientsService,
    Project,
    ProjectsService
} from '../../apiclient';

type NewProjectProps = {
};

function NewProject(props: NewProjectProps) {
    let params = useParams();

    const [client, setClient] = useState<Client>()
    const [name, setName] = useState("")

    useEffect(() => {
        if (params.clientId) {
            ClientsService.getClient(params.clientId)
            .then(client => setClient(client))
        }
    }, [params.clientId]);

    const submit = () => {
        const project: Project = {
            name: name
        }
        if (params.clientId) {
            console.log("submit", project)

            ProjectsService.createProject(params.clientId, project)
            // .then(project => dispatch(addProject(project)))
        }
    }

    return (
        <>
            <div className="contentbox">
                <div className="field">
                    <label htmlFor="clientId">Client ID</label>
                    <InputText id="clientId" value={client?.id} disabled />
                </div>
                <div className="field">
                    <label htmlFor="clientName">Client</label>
                    <InputText id="clientName" value={client?.name} disabled />
                </div>
                <div className="field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <Button label="Submit" icon="pi pi-save" onClick={submit} />
            </div>
        </>
    );
}

export default NewProject;
