import { useRef } from 'react';
import {
    Link,
    useNavigate
} from "react-router-dom";

import { Menubar } from 'primereact/menubar';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';


import { User } from 'firebase/auth';
import { OpenAPI } from '../../apiclient';
import { logout } from '../../firebase';


type MenuProps = {
    user: User
};

function Menu(props: MenuProps) {
    const navigate = useNavigate();

    OpenAPI.TOKEN = async () => {
        return await props.user.getIdToken()
    };

    const op = useRef<OverlayPanel>(null);

    let avatar: JSX.Element
    if (props.user.photoURL && props.user.photoURL !== "") {
        avatar = <Avatar image={props.user.photoURL || ""} shape="circle" />
    } else {
        let label = props.user.displayName ? props.user.displayName[0] : "X"
        avatar = <Avatar label={label} shape="circle" />
    }

    let profileButton = <>
        <Button className="profileButton" type="button" onClick={(e) => op.current?.toggle(e)}>
            {avatar}
        </Button>
        <OverlayPanel ref={op}>
            <Link to="#" onClick={logout}>Log out</Link>
        </OverlayPanel>
    </>

    const brand = <>
        <div className="brand text-5xl line-height-3">
            Wakati
        </div>
    </>

    const items = [
        {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-compass',
            command: () => {
                navigate(`/`);
            }
        },
        {
            label: 'Track',
            icon: 'pi pi-fw pi-stopwatch',
        },
        {
            label: 'Reports',
            icon: 'pi pi-fw pi-chart-bar',
        },
        {
            label: 'Settings',
            icon: 'pi pi-fw pi-cog',

        }
    ];

    return (
        <>
            <Menubar model={items} start={brand} end={profileButton} />
        </>
    );
}

export default Menu;
