import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Client, ClientsService } from '../../apiclient';
import { useAppDispatch } from '../../app/hooks';
import { addClient } from './clientSlice';

type NewClientProps = {
};

function NewClient(props: NewClientProps) {
    const dispatch = useAppDispatch();
    const [name, setName] = useState("")

    const submit = () => {
        const client: Client = {
            name: name
        }

        ClientsService.createClient(client)
        .then(client => dispatch(addClient(client)))
    }

    return (
        <>
            <div className="contentbox">
                <div className="field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <Button label="Submit" icon="pi pi-save" onClick={submit} />
            </div>
        </>
    );
}

export default NewClient;
