/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Sign in a user
     * Signs in a user. Will create the user if not known
     * @returns any Success
     * @throws ApiError
     */
    public static signIn(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/signin',
            errors: {
                403: `Unauthorized`,
                500: `Server error`,
            },
        });
    }

}
