type TrackActivityProps = {
};

function TrackActivity(props: TrackActivityProps) {
    return (
        <>
            TrackActivity
        </>
    );
}

export default TrackActivity;
