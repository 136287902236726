/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Record } from '../models/Record';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecordsService {

    /**
     * Create a time record
     * Creates a new time record
     * @param body Record object
     * @returns Record Success
     * @throws ApiError
     */
    public static createRecord(
        body: Record,
    ): CancelablePromise<Record> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/records',
            body: body,
            errors: {
                404: `Not found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a list of records
     * Return a list of records the logged in user has access to
     * @returns Record Success
     * @throws ApiError
     */
    public static listRecords(): CancelablePromise<Array<Record>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/records',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

}
