import { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import parse from 'parse-duration';

import {
    Client,
    ClientsService,
    Project,
    ProjectsService,
    Record,
    RecordsService
} from '../../apiclient';
// import { selectClients } from '../clients/clientSlice';

type DashboardProps = {
};


type FormData = {
    clientId?: string
    projectId?: string
    date?: Date
    duration?: string
    description?: string
    tags?: string[]
};

function Dashboard(props: DashboardProps) {
    let [clients, setClients] = useState<Client[]>([]);
    let [projects, setProjects] = useState<Project[]>([]);
    let [records, setRecords] = useState<Record[]>([]);

    let [selectedClientId, selectClientId] = useState<string>();
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm<FormData>();


    // const getFormErrorMessage: Partial<FieldErrorsImpl<any>> = (name: any) => {
    //     return errors[name] && <small className="p-error">{errors[name].message}</small>
    // };

    useEffect(() => {
        ClientsService.listClients()
            .then(clients => setClients(clients))

        RecordsService.listRecords()
            .then(records => setRecords(records))
    }, []);

    useEffect(() => {
        ProjectsService.listProjects(selectedClientId!)
            .then(projects => setProjects(projects));
    }, [selectedClientId]);

    // Automatically select project if client only has one
    useEffect(() => {
        if (projects.length === 1) {
            setValue("projectId", projects[0].id);
        }
    }, [projects, setValue])

    const onSubmit = handleSubmit(data => {
        console.log("data", data);
        console.log(data.date?.toISOString())

        let date = data.date!
        let record: Record = {
            project_id: data.projectId!,
            date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`,
            duration: parse(data.duration!, "s") || 0,
            description: data.description,
            tags: data.tags
        }

        RecordsService.createRecord(record)
            .then(res => setRecords([...records, record]));

        console.log("record", record)
        reset();
    });


    const dateBodyTemplate = (rowData: Record) => {
        return new Date(Date.parse(rowData.date)).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    return (
        <>
            <h1>Dashboard</h1>
            <div className="grid">
                <div className="col-8">
                    <h2>Track time</h2>
                    <form onSubmit={onSubmit} className="p-fluid">
                        <div className="formgrid grid">
                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="clientId" control={control} rules={{ required: 'Client is required.' }} render={({ field }) => (
                                        <Dropdown
                                            id={field.name}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                selectClientId(e.value);
                                            }}
                                            options={clients}
                                            optionLabel="name"
                                            optionValue="id" />
                                    )} />
                                    <label htmlFor="client" className={classNames({ 'p-error': errors.clientId })}>Client*</label>
                                </span>
                                {/* {getFormErrorMessage('client')} */}
                            </div>

                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="projectId" control={control} rules={{ required: 'Project is required.' }} render={({ field }) => (
                                        <Dropdown
                                            id={field.name}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}
                                            options={projects}
                                            optionLabel="name"
                                            optionValue="id" />
                                    )} />
                                    <label htmlFor="project" className={classNames({ 'p-error': errors.projectId })}>Project*</label>
                                </span>
                            </div>
                        </div>

                        <div className="formgrid grid mt-3">
                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="date" control={control} rules={{ required: 'Date is required.' }} render={({ field }) => (
                                        <Calendar id="date"
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                            }}></Calendar>
                                    )} />
                                    <label htmlFor="date" className={classNames({ 'p-error': errors.date })}>Date*</label>
                                </span>
                            </div>

                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="duration" control={control} rules={{ required: 'Duration is required.' }} render={({ field }) => (
                                        <>
                                            <div className="p-inputgroup">
                                                <InputMask id="duration"
                                                    mask="9?9h 9?9m"
                                                    placeholder="1h 10m"
                                                    onChange={(e) => {
                                                        // console.log(parse(e.value, "s"))
                                                        field.onChange(e.value)
                                                    }}></InputMask>
                                                <span className="p-inputgroup-addon">{new Date(parse(field.value!) || 0).toISOString().slice(11, 16)}</span>
                                            </div>
                                        </>
                                    )} />
                                    {/* {new Date(parse(duration!)).toISOString().substring(11, 19)} */}
                                    <label htmlFor="duration" className={classNames({ 'p-error': errors.duration })}>Duration*</label>
                                </span>
                            </div>
                        </div>
                        <div className="formgrid grid mt-3">
                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="description" control={control} render={({ field }) => (
                                        <InputText id="description"
                                            onChange={(e) => {
                                                field.onChange(e.currentTarget.value)
                                            }}></InputText>
                                    )} />
                                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Description*</label>
                                </span>
                            </div>
                        </div>
                        <div className="formgrid grid mt-3">
                            <div className="field col">
                                <span className="p-float-label">
                                    <Controller name="tags" control={control} render={({ field }) => (
                                        <Chips id="tags"
                                            separator=","
                                            value={field.value}
                                            onChange={(e) => {
                                                console.log(e.value)
                                                field.onChange(e.value)
                                            }}></Chips>
                                    )} />
                                    <label htmlFor="tags" className={classNames({ 'p-error': errors.tags })}>Tags*</label>
                                </span>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <Button label="Submit" icon="pi pi-save" type="submit" />
                            </div>
                        </div>
                    </form>


                    <div className="grid">
                        <h2>Records</h2>
                        <DataTable value={records} responsiveLayout="scroll">
                            <Column field="id" header="ID"></Column>
                            <Column field="project_id" header="Project"></Column>
                            <Column field="date" header="Date" body={dateBodyTemplate}></Column>
                            <Column field="duration" header="Duration"></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="col-4">
                    <h2>Stats</h2>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
